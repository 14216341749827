export function forwardToUrl(url: string, newWindow = false) {
    if (newWindow) {
        const win = window.open(url, '_blank');
        if (win !== null) {
            win.focus();
            return;
        }
    }

    window.location.href = url;
}
